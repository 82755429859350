













































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {}
})
export default class UpcomingPoolItem extends Vue {
  @Prop({ required: true, default: null }) model!: any
}
